import React from 'react'
import Client from './../components/Client'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import bkn from '../assets/img/client/bkn.png'
import kemendikbud from '../assets/img/client/kemdikbud.png'
import pddikti from '../assets/img/client/pddikti.png'
import kampusMerdeka from '../assets/img/client/kampusmerdeka.png'
import sscasn from '../assets/img/client/sscasn.png'
import untad from '../assets/img/client/untad.png'
import sapk from '../assets/img/client/sapk.png'

function Carousel() {
    return (
        <section className='client-section ptb-100'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-12'>
                        <OwlCarousel
                            loop
                            autoplay
                            autoplayHoverPause
                            autoplayTimeout={4500}
                            autoplaySpeed={4500}
                            margin={15}
                            responsive={{
                                0: { items: 2 },
                                500: { items: 3 },
                                600: { items: 4 },
                                800: { items: 5 },
                                1200: { items: 6 },
                            }}
                            className='owl-carousel owl-theme clients-carousel dot-indicator'>
                            <Client img={kemendikbud} />
                            <Client img={pddikti} />
                            <Client img={kampusMerdeka} />
                            <Client img={bkn} />
                            <Client img={sscasn} />
                            <Client img={untad} />
                            <Client img={sapk} />
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Carousel
