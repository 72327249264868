import React from 'react';
import footerBg from '../assets/img/footer-bg.png';
import logo from '../assets/img/logo.png';
import { NavLink } from 'react-router-dom';

function Footer(props) {
    return (
        <div>
            {/* <div className='shape-img subscribe-wrap'>
                <img src={footerTop} alt='footer shape' className='img-fluid' />
            </div> */}

            <footer className='footer-section'>
                <div
                    className='footer-top py-5 background-img-2'
                    style={{
                        background: `url(${footerBg})no-repeat center top / cover`,
                    }}
                >
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-3 mb-3 mb-lg-0'>
                                <div className='footer-nav-wrap text-white'>
                                    <img
                                        src={logo}
                                        width={200}
                                        alt='footer-logo'
                                        className='img-fluid mb-3'
                                    />
                                    <p>
                                        Bagian dari Biro Keuangan dan Umum
                                        Universitas Tadulako yang membidangi
                                        kepegawaian, pengembangan dan disiplin.
                                    </p>
                                    <div className='social-list-wrap'>
                                        <ul className='social-list list-inline list-unstyled'>
                                            <li className='list-inline-item'>
                                                <a
                                                    href='./'
                                                    target='_blank'
                                                    title='Facebook'
                                                >
                                                    <span className='ti-facebook' />
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a
                                                    href='./'
                                                    target='_blank'
                                                    title='Twitter'
                                                >
                                                    <span className='ti-twitter' />
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a
                                                    href='./'
                                                    target='_blank'
                                                    title='Instagram'
                                                >
                                                    <span className='ti-instagram' />
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a
                                                    href='https://www.youtube.com/channel/UCa9ZN9q4m3NiT6O2aUII9Ig'
                                                    rel='noreferrer'
                                                    target='_blank'
                                                    title='Youtube'
                                                >
                                                    <span className='ti-youtube' />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 ml-auto mb-4 mb-lg-0'>
                                <div className='footer-nav-wrap text-white'>
                                    <h5 className='mb-3 text-white'>Tautan</h5>
                                    <ul className='list-unstyled'>
                                        <li className='mb-2'>
                                            <a
                                                href='https://untad.ac.id'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Universitas Tadulako
                                            </a>
                                        </li>
                                        <li className='mb-2'>
                                            <a
                                                href='https://ppid.untad.ac.id'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                PPID UNTAD
                                            </a>
                                        </li>
                                        <li className='mb-2'>
                                            <a
                                                href='https://sister.kemdikbud.go.id'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                SISTER UNTAD
                                            </a>
                                        </li>
                                        <li className='mb-2'>
                                            <a
                                                href='https://pddiki.kemdikbud.go.id'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Pangkalan Data Pendidikan Tinggi
                                            </a>
                                        </li>
                                        <li className='mb-2'>
                                            <a
                                                href='https://kampusmerdeka.kemdikbud.go.id'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Kampus Merdeka
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3 ml-auto mb-4 mb-lg-0'>
                                <div className='footer-nav-wrap text-white'>
                                    <h5 id='kontak' className='mb-3 text-white'>
                                        Kontak
                                    </h5>
                                    <ul className='list-unstyled support-list'>
                                        <li className='mb-2 d-flex align-items-center'>
                                            <span className='ti-location-pin mr-2' />
                                            Gedung Rektorat Lt. 2
                                            <br />
                                            Kampus Bumi Tadulako
                                        </li>
                                        <li className='mb-2 d-flex align-items-center'>
                                            <span className='ti-mobile mr-2' />
                                            <a href='tel:+62451422611'>
                                                +62 451 422611
                                            </a>
                                        </li>
                                        <li className='mb-2 d-flex align-items-center'>
                                            <span className='ti-email mr-2' />
                                            <a href='mailto:kepegawaian.untad@gmail.com'>
                                                kepegawaian.untad@gmail.com
                                            </a>
                                        </li>
                                        <li className='mb-2 d-flex align-items-center'>
                                            <span className='ti-world mr-2' />
                                            <NavLink to='/'>
                                                kepegawaian.untad.ac.id
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='footer-nav-wrap text-white'>
                                    <h5 className='mb-3 text-white'>Lokasi</h5>
                                    <div className='google-map-code'>
                                        <iframe
                                            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15957.5727226827!2d119.8936937!3d-0.8364322!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf8a9048c767a7489!2sTadulako%20University!5e0!3m2!1sen!2sid!4v1613906722916!5m2!1sen!2sid'
                                            width='299'
                                            height='171'
                                            style={{ border: 0 }}
                                            loading='lazy'
                                            title='Universitas Tadulako'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
