import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Sidebar from './../../components/Sidebar';
import { NavLink } from 'react-router-dom';

function LayananShow() {
    let { slug } = useParams();
    const [layanan, setlayanan] = useState([]);

    const getlayanan = async () => {
        try {
            let { data } = await axios.get('layanan/' + slug);
            setlayanan(data.data);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getlayanan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='main pt-100'>
            <div className='module'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8'>
                            <article className='post'>
                                <div className='post-wrapper'>
                                    <div className='post-header'>
                                        <h1 className='post-title'>
                                            {layanan.judul}
                                        </h1>
                                        <ul className='post-meta'>
                                            <li>{layanan.published_at}</li>
                                            <li>
                                                Dilihat {layanan.views} kali
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='post-content text-justify'>
                                        {parse(layanan.konten | '')}
                                    </div>
                                    <div className='post-image'>
                                        <p className='font-weight-bold'>
                                            {layanan.gambar_desc}
                                        </p>
                                        <img
                                            src={layanan.gambar}
                                            alt=''
                                            className='img-fluid'
                                        />
                                    </div>
                                    <div className='post-footer text-center'>
                                        <NavLink
                                            to='/layanan'
                                            className='btn solid-btn mt-1'
                                        >
                                            Kembali
                                        </NavLink>
                                    </div>
                                </div>
                            </article>
                        </div>
                        {/* sidebar */}
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LayananShow;
