import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

function DosenIndex(props) {
    const [label, setLabel] = useState([]);
    const [rekapL, setRekapL] = useState([]);
    const [rekapP, setRekapP] = useState([]);
    const [pendidikanL, setPendidikanL] = useState([]);
    const [pendidikanP, setPendidikanP] = useState([]);
    const [fungsional, setFungsional] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const getLabel = async () => {
        try {
            let { data } = await axios.get('statistik/dosen/label');
            setLabel(data.label);
        } catch (e) {
            console.log(e.message);
        }
    };

    const getRekap = async () => {
        setLoading1(true);
        try {
            let { data } = await axios.get('statistik/dosen/rekap');
            setLoading1(false);
            setRekapL(Object.values(data.rekap.L));
            setRekapP(Object.values(data.rekap.P));
        } catch (e) {
            console.log(e.message);
        }
    };

    const getPendidikan = async () => {
        setLoading2(true);
        try {
            let { data } = await axios.get('statistik/dosen/pendidikan');
            setLoading2(false);
            setPendidikanL(data.pendidikan.L);
            setPendidikanP(data.pendidikan.P);
        } catch (e) {
            console.log(e.message);
        }
    };

    const getFungsional = async () => {
        setLoading3(true);
        try {
            let { data } = await axios.get('statistik/dosen/fungsional');
            setLoading3(false);
            setFungsional(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        if (getLabel()) {
            getRekap();
            getFungsional();
            getPendidikan();
        }
    }, []);

    const labels = label;

    const options = {
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        tooltips: {
            enabled: true,
        },
        legend: { position: 'top' },
        animation: {
            onComplete: function () {
                const chartInstance = this.chart;
                const ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.fillStyle = 'rgba(0, 0, 0, 1)';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset, i) {
                    const meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        const data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            },
        },
    };

    const dataRekap = {
        labels: labels,
        datasets: [
            {
                label: 'Pria',
                data: rekapL,
                backgroundColor: 'rgba(0,123,255, 0.4)',
                borderColor: 'rgba(0,123,255, 1)',
                borderWidth: 1,
            },
            {
                label: 'Wanita',
                data: rekapP,
                backgroundColor: 'rgba(220, 53, 69, 0.4)',
                borderColor: 'rgba(220, 53, 69, 1)',
                borderWidth: 1,
            },
        ],
    };

    const dataPendidikan = {
        labels: ['S3', 'S2', 'S1', 'Profesi', 'Spesialis'],
        datasets: [
            {
                label: 'Pria',
                data: [
                    pendidikanL.S3,
                    pendidikanL.S2,
                    pendidikanL.S1,
                    pendidikanL.Profesi,
                    pendidikanL.Spesialis,
                ],
                backgroundColor: 'rgba(0,123,255, 0.4)',
                borderColor: 'rgba(0,123,255, 1)',
                borderWidth: 1,
            },
            {
                label: 'Wanita',
                data: [
                    pendidikanP.S3,
                    pendidikanP.S2,
                    pendidikanP.S1,
                    pendidikanP.Profesi,
                    pendidikanP.Spesialis,
                ],
                backgroundColor: 'rgba(220, 53, 69, 0.4)',
                borderColor: 'rgba(220, 53, 69, 1)',
                borderWidth: 1,
            },
        ],
    };

    const dataFungsional = {
        labels: labels,
        datasets: [
            {
                label: 'Profesor',
                data: fungsional.profesor,
                backgroundColor: 'rgba(40, 167, 69, 0.4)',
                borderColor: 'rgba(40, 167, 69, 1)',
                borderWidth: 1,
            },
            {
                label: 'Lektor Kepala',
                data: fungsional.lektor_kepala,
                backgroundColor: 'rgba(0, 123, 255, 0.4)',
                borderColor: 'rgba(0, 123, 255, 1)',
                borderWidth: 1,
            },
            {
                label: 'Lektor',
                data: fungsional.lektor,
                backgroundColor: 'rgba(255, 193, 7, 0.4)',
                borderColor: 'rgba(255, 193, 7, 1)',
                borderWidth: 1,
            },
            {
                label: 'Asisten Ahli',
                data: fungsional.asisten_ahli,
                backgroundColor: 'rgba(220, 53, 69, 0.4)',
                borderColor: 'rgba(220, 53, 69, 1)',
                borderWidth: 1,
            },
            {
                label: 'Tenaga Pengajar',
                data: fungsional.tenaga_pengajar,
                backgroundColor: 'rgba(108, 117, 125, 0.4)',
                borderColor: 'rgba(108, 117, 125, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <section className='our-blog-section px-sm-5 px-2 py-5 gray-light-bg'>
            <div className='container'>
                <div className='row justify-content-center mt-1 mb-5 tag-cloud'>
                    {label.map((fakultas, index) => {
                        return (
                            <NavLink
                                key={index}
                                title={`Daftar Dosen ${fakultas}`}
                                to={`/statistik/dosen/${fakultas.toLowerCase()}`}
                            >
                                {fakultas}
                            </NavLink>
                        );
                    })}
                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-6 col-sm'>
                        <div className='text-center py-4 h-100 text-muted mb-0'>
                            <h5>Rekap Dosen PNS</h5>
                            {loading1 ? (
                                <LinearProgress />
                            ) : (
                                <>
                                    <Bar data={dataRekap} options={options} />
                                    <NavLink
                                        to='/statistik/dosen/rekap'
                                        className='btn btn-sm btn-info mt-1'
                                    >
                                        Lihat Detail
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-6 col-sm'>
                        <div className='text-center py-4 h-100 text-muted mb-0'>
                            <h5>Pendidikan Dosen PNS</h5>
                            {loading2 ? (
                                <LinearProgress />
                            ) : (
                                <>
                                    <Bar
                                        data={dataPendidikan}
                                        options={options}
                                    />
                                    <NavLink
                                        to='/statistik/dosen/pendidikan'
                                        className='btn btn-sm btn-info mt-1'
                                    >
                                        Lihat Detail
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col'>
                        <div className='text-center py-4 h-100 text-muted mb-0'>
                            <h5>Fungsional Dosen PNS</h5>
                            {loading3 ? (
                                <LinearProgress />
                            ) : (
                                <>
                                    <Bar
                                        data={dataFungsional}
                                        height={100}
                                        options={options}
                                    />
                                    <NavLink
                                        to='/statistik/dosen/fungsional'
                                        className='btn btn-sm btn-info mt-1'
                                    >
                                        Lihat Detail
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DosenIndex;
