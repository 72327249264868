import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'

function DosenFungsional() {
    const [total, setTotal] = useState([])
    const [fungsional, setFungsional] = useState([])
    const [loading, setLoading] = useState(false)

    const getFungsional = async () => {
        setLoading(true)
        try {
            let { data } = await axios.get('statistik/dosen/fungsional?detail')
            setLoading(false)
            setTotal(data.total)
            setFungsional(data.fungsional)
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        getFungsional()
    }, [])

    return (
        <section className='main pt-5 pb-100 gray-light-bg'>
            <div className='container'>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <div className='row justify-content-center'>
                            <div className="table-responsive">
                                <table
                                    className='table table-striped table-bordered'
                                    style={{ width: '100%' }}>
                                    <thead
                                        className='font-weight-bold text-white'
                                        style={{ backgroundColor: '#7952b3' }}>
                                        <tr className='text-center'>
                                            <th scope='col'>No</th>
                                            <th scope='col'>Unit Kerja</th>
                                            <th scope='col'>Profesor</th>
                                            <th scope='col'>Lektor Kepala</th>
                                            <th scope='col'>Lektor</th>
                                            <th scope='col'>Asisten Ahli</th>
                                            <th scope='col'>Tenaga Pengajar</th>
                                            <th scope='col'>Jumlah</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fungsional.map((item, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className='text-center'>
                                                    <th scope='row'>{index + 1}</th>
                                                    <td className='text-left'>
                                                        <NavLink to={`${item.kode.toLowerCase()}`}
                                                        className='detail-link'
                                                        style={{ fontSize: '15px', fontWeight:400 }}>
                                                            {item.unit}
                                                        </NavLink>
                                                    </td>
                                                    <td>{item.profesor}</td>
                                                    <td>{item.lektor_kepala}</td>
                                                    <td>{item.lektor}</td>
                                                    <td>{item.asisten_ahli}</td>
                                                    <td>{item.tenaga_pengajar}</td>
                                                    <td>{item.jumlah}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr className='font-weight-bold text-center'>
                                            <td colSpan={2}>Total</td>
                                            <td>{total.profesor}</td>
                                            <td>{total.lektor_kepala}</td>
                                            <td>{total.lektor}</td>
                                            <td>{total.asisten_ahli}</td>
                                            <td>{total.tenaga_pengajar}</td>
                                            <td>{total.jumlah}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='text-center'>
                            <NavLink
                                to='/statistik/dosen'
                                className='btn solid-btn mt-1'>
                                Kembali
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </section>
    )
}

export default DosenFungsional
