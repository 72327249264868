import React from 'react';
import Counter from './Counter';
import { Link } from 'react-scroll';

import cover from '../assets/img/cover/1.jpg';
import hero1 from '../assets/img/hero-animation-01.svg';
import hero2 from '../assets/img/hero-animation-02.svg';
import hero3 from '../assets/img/hero-animation-03.svg';
import hero4 from '../assets/img/hero-animation-04.svg';

function Hero() {
    return (
        <section
            className='hero-section pt-100 background-img'
            style={{
                background: `url(${cover}) no-repeat center center / cover`,
            }}
        >
            <div className='circles'>
                <div className='point animated-point-1' />
                <div className='point animated-point-2' />
                <div className='point animated-point-3' />
                <div className='point animated-point-4' />
                <div className='point animated-point-5' />
                <div className='point animated-point-6' />
                <div className='point animated-point-7' />
                <div className='point animated-point-8' />
                <div className='point animated-point-9' />
            </div>
            <div className='container'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-md-6 col-lg-6'>
                        <div className='hero-content-left ptb-100 text-white'>
                            <h1 className='text-white'>
                                <span>Kepegawaian</span> UNTAD
                            </h1>
                            <p className='lead text-justify'>
                                Bagian dari Biro Keuangan dan Umum Universitas
                                Tadulako yang mempunyai tugas melaksanakan
                                urusan kepegawaian, pengembangan, dan disiplin
                                bagi Tenaga Pendidik dan Tenaga Kependidikan.
                            </p>
                            <Link
                                smooth
                                to='berita'
                                className='btn app-store-btn'
                            >
                                Lihat berita terbaru
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-5'>
                        <div className='hero-animation-img d-none d-sm-block'>
                            <img
                                className='img-fluid d-block animation-one'
                                src={hero4}
                                alt='animation'
                            />
                            <img
                                className='img-fluid d-none d-lg-block animation-two'
                                src={hero1}
                                alt='animation'
                                width={120}
                            />
                            <img
                                className='img-fluid d-none d-lg-block animation-three'
                                src={hero2}
                                alt='animation'
                                width={120}
                            />
                            <img
                                className='img-fluid d-none d-lg-block animation-four'
                                src={hero3}
                                alt='animation'
                                width={230}
                            />
                        </div>
                    </div>
                </div>
                <Counter />
            </div>
        </section>
    );
}

export default Hero;
