import React, { useState, useEffect } from 'react';
import axios from 'axios';
import News from './../components/News';
import { NavLink } from 'react-router-dom';

function Home() {
    const [berita, setBerita] = useState([]);

    const getBerita = async () => {
        try {
            let { data } = await axios.get('berita/6');
            setBerita(data.data);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getBerita();
    }, []);

    return (
        <div className='main'>
            <section id='layanan' className='promo-section mt-5 ptb-100'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <div className='section-heading text-center mb-5'>
                                <h4>Layanan Digital</h4>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-sm-6 mb-lg-0'>
                            <a
                                href={`http://simpeg.untad.ac.id/sdm/kenaikan-pangkat`}
                            >
                                <div className='card single-promo-card single-promo-hover'>
                                    <div className='card-body'>
                                        <div className='pb-2'>
                                            <span className='ti-stats-up icon-md color-secondary' />
                                        </div>
                                        <div className='pt-2 pb-3'>
                                            <h5>Kenaikan Pangkat</h5>
                                            <p className='text-muted mb-0'>
                                                Layanan ajuan kenaikan pangkat
                                                PNS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <a
                                href={`http://simpeg.untad.ac.id/sdm/pencantuman-gelar`}
                            >
                                <div className='card single-promo-card single-promo-hover mb-lg-0'>
                                    <div className='card-body'>
                                        <div className='pb-2'>
                                            <span className='ti-medall icon-md color-secondary' />
                                        </div>
                                        <div className='pt-2 pb-3'>
                                            <h5>Pencantuman Gelar</h5>
                                            <p className='text-muted mb-0'>
                                                Layanan ajuan pencantuman gelar
                                                akademik PNS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <a href={`javascript:void(0)`}>
                                <div className='card single-promo-card single-promo-hover mb-lg-0'>
                                    <div className='card-body'>
                                        <div className='pb-2'>
                                            <span className='ti-home icon-md color-secondary' />
                                        </div>
                                        <div className='pt-2 pb-3'>
                                            <h5>Pensiun</h5>
                                            <p className='text-muted mb-0'>
                                                Layanan ajuan pensiun dan
                                                pemberhentian PNS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                            <a href={`javascript:void(0)`}>
                                <div className='card single-promo-card single-promo-hover mb-lg-0'>
                                    <div className='card-body'>
                                        <div className='pb-2'>
                                            <span className='ti-briefcase icon-md color-secondary' />
                                        </div>
                                        <div className='pt-2 pb-3'>
                                            <h5>Tugas Belajar</h5>
                                            <p className='text-muted mb-0'>
                                                Layanan ajuan izin dan tugas
                                                belajar PNS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section
                id='berita'
                className='our-blog-section mt-lg-n2 mt-5 pt-5 gray-light-bg'
            >
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <div className='section-heading text-center mb-5'>
                                <h4>Berita Terbaru</h4>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {berita.map((item, index) => {
                            return (
                                <News
                                    key={index}
                                    title={item.judul}
                                    img={item.gambar}
                                    link={item.slug}
                                    date={item.published_at}
                                    views={item.views}
                                    category='Berita'
                                />
                            );
                        })}
                    </div>
                    <div className='row text-center'>
                        <div className='col-sm-12 my-5'>
                            <NavLink to='/berita' className='btn solid-btn'>
                                Lihat Semua Berita
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
