import React from 'react'

function Client(props) {
    return (
        <div>
            <div className='item single-client'>
                <img
                    src={props.img}
                    alt={`client-${props.img}`}
                    className='client-img'
                />
            </div>
        </div>
    )
}

export default Client
