import React from "react";
import { NavLink } from "react-router-dom";

function News(props) {
  return (
    <div className="col-md-4">
      <div className="single-blog-card card border-0 shadow-sm">
        <span className="category position-absolute badge badge-pill badge-primary">
          {props.category}
        </span>
        <img
          src={props.img}
          className="card-img-top position-relative"
          alt="berita"
          style={{ maxHeight: "230px", objectFit: "cover" }}
        />
        <div className="card-body">
          <div className="post-meta mb-2">
            <ul className="list-inline meta-list d-flex justify-content-between">
              <li className="list-inline-item">{props.date}</li>
              <li className="list-inline-item">
                Dilihat <span>{props.views}</span> kali
              </li>
            </ul>
          </div>
          <h3 className="h5 card-title text-justify">{props.title}</h3>
        </div>
        <div className="card-footer bg-transparent">
          <NavLink to={`/berita/${props.link}`} className="detail-link">
            Selengkapnya <span className="ti-arrow-right" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default News;
