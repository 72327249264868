import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

function TendikRekap() {
    const [total, setTotal] = useState([]);
    const [rekap, setRekap] = useState([]);
    const [loading, setLoading] = useState(false);

    const getRekap = async () => {
        setLoading(true);
        try {
            let { data } = await axios.get('statistik/tendik/rekap?detail');
            setLoading(false);
            setTotal(data.total);
            setRekap(data.rekap);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getRekap();
    }, []);

    return (
        <section className='main pt-5 pb-100 gray-light-bg'>
            <div className='container'>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <div className='row justify-content-center'>
                            <div className='table-responsive'>
                                <table
                                    className='table table-striped table-bordered'
                                    style={{ width: '100%' }}
                                >
                                    <thead
                                        className='font-weight-bold text-white'
                                        style={{ backgroundColor: '#7952b3' }}
                                    >
                                        <tr className='text-center'>
                                            <th scope='col'>No</th>
                                            <th scope='col'>Unit Kerja</th>
                                            <th scope='col' width='10%'>
                                                Pria
                                            </th>
                                            <th scope='col' width='10%'>
                                                Wanita
                                            </th>
                                            <th scope='col' width='10%'>
                                                Jumlah
                                            </th>
                                            <th scope='col' width='10%'>
                                                %
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rekap.map((item, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className='text-center'
                                                >
                                                    <th scope='row'>
                                                        {index + 1}
                                                    </th>
                                                    <td className='text-left'>
                                                        <NavLink
                                                            to={`${item.kode.toLowerCase()}`}
                                                            className='detail-link'
                                                            style={{
                                                                fontSize:
                                                                    '15px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            {item.unit}
                                                        </NavLink>
                                                    </td>
                                                    <td>{item.pria}</td>
                                                    <td>{item.wanita}</td>
                                                    <td>{item.jumlah}</td>
                                                    <td className='text-right'>
                                                        {item.persentase.toFixed(
                                                            2
                                                        )}{' '}
                                                        %
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr className='font-weight-bold text-center'>
                                            <td colSpan={2}>Total</td>
                                            <td>{total.pria}</td>
                                            <td>{total.wanita}</td>
                                            <td>{total.jumlah}</td>
                                            <td className='text-right'>
                                                100 %
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='text-center'>
                            <NavLink
                                to='/statistik/tendik'
                                className='btn solid-btn mt-1'
                            >
                                Kembali
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}

export default TendikRekap;
