import React from 'react'
import cover from '../assets/img/cover/4.jpg'

function Header(props) {
    return (
        <section
            className='hero-section ptb-100 background-img'
            style={{
                background: `url(${cover}) no-repeat center center / cover`,
            }}>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-8 col-lg-7'>
                        <div className='page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0'>
                            <h1
                                id='header-title'
                                className='text-white mb-0'>
                                {props.title}
                            </h1>
                            <div
                                id='header-subtitle'
                                className='custom-breadcrumb'>
                                    {props.subtitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header
