import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';

function DosenPendidikan() {
    const [total, setTotal] = useState([]);
    const [pendidikan, setPendidikan] = useState([]);
    const [loading, setLoading] = useState(false);

    const getPendidikan = async () => {
        setLoading(true);
        try {
            let { data } = await axios.get('statistik/dosen/pendidikan?detail');
            setLoading(false);
            setTotal(data.total);
            setPendidikan(data.pendidikan);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getPendidikan();
    }, []);

    return (
        <section className='main pt-5 pb-100 gray-light-bg'>
            <div className='container'>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <div className='row justify-content-center'>
                            <div className='table-responsive'>
                                <table
                                    className='table table-striped table-bordered'
                                    style={{ width: '100%' }}
                                >
                                    <thead
                                        className='font-weight-bold text-white'
                                        style={{ backgroundColor: '#7952b3' }}
                                    >
                                        <tr className='text-center'>
                                            <th scope='col'>No</th>
                                            <th scope='col'>Unit Kerja</th>
                                            <th scope='col' width='10%'>
                                                S3
                                            </th>
                                            <th scope='col' width='10%'>
                                                S2
                                            </th>
                                            <th scope='col' width='10%'>
                                                S1
                                            </th>
                                            <th scope='col'>Profesi</th>
                                            <th scope='col'>Spesialis</th>
                                            <th scope='col'>Jumlah</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pendidikan.map((item, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className='text-center'
                                                >
                                                    <th scope='row'>
                                                        {index + 1}
                                                    </th>
                                                    <td className='text-left'>
                                                        <NavLink
                                                            to={`${item.kode.toLowerCase()}`}
                                                            className='detail-link'
                                                            style={{
                                                                fontSize:
                                                                    '15px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            {item.unit}
                                                        </NavLink>
                                                    </td>
                                                    <td>{item.s3}</td>
                                                    <td>{item.s2}</td>
                                                    <td>{item.s1}</td>
                                                    <td>{item.profesi}</td>
                                                    <td>{item.spesialis}</td>
                                                    <td>{item.jumlah}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr className='font-weight-bold text-center'>
                                            <td colSpan={2}>Total</td>
                                            <td>{total.s3}</td>
                                            <td>{total.s2}</td>
                                            <td>{total.s1}</td>
                                            <td>{total.profesi}</td>
                                            <td>{total.spesialis}</td>
                                            <td>{total.jumlah}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='text-center'>
                            <NavLink
                                to='/statistik/dosen'
                                className='btn solid-btn mt-1'
                            >
                                Kembali
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}

export default DosenPendidikan;
