import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
// import 'datatables.net-dt/js/dataTables.bootstrap4.min'
// import 'datatables.net-dt/css/dataTables.bootstrap4.min.css'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import { LinearProgress } from '@material-ui/core'

import $ from 'jquery'
import { NavLink } from 'react-router-dom';

function LayananIndex(props) {
    const [layanan, setlayanan] = useState([])
    const [loading, setLoading] = useState(false)

    const getlayanan = async () => {
        setLoading(true)
        try {
            let { data } = await axios.get('layanan')
            setLoading(false)
            setlayanan(data.data)
            $('.datatable').DataTable()
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        getlayanan()
    }, [])

    return (
        <section className='main pt-5 pb-100'>
            <div className='container'>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <div className='row justify-content-center text-center'>
                        <div className="table-responsive">
                            <table
                                className='table table-striped table-bordered datatable'
                                style={{ width: '100%' }}>
                                <thead
                                    className='font-weight-bold text-white'
                                    style={{ backgroundColor: '#7952b3' }}>
                                    <tr className='text-center'>
                                        <th scope='col'>No</th>
                                        <th scope='col'>Judul</th>
                                        <th scope='col'>Diperbarui</th>
                                        <th scope='col'>Dilihat</th>
                                        <th scope='col'>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {layanan.map((layanan, index) => {
                                        return (
                                            <tr key={index} className='text-center'>
                                                <th
                                                    scope='row'>
                                                    {index + 1}
                                                </th>
                                                <td className='text-left'>{`${layanan.judul}`}</td>
                                                <td>{layanan.published_at}</td>
                                                <td>{layanan.views} kali</td>
                                                <td>
                                                    <NavLink to={`/layanan/${layanan.slug}`}>
                                                        <span className='ti-eye' />
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default LayananIndex
