import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../assets/img/logo.png';
// import { Link } from 'react-scroll'

function Navbar(props) {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 60) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            <nav
                className={`navbar mx-auto navbar-expand-lg fixed-top bg-transparent ${
                    props.isAffix ? 'affix' : ''
                } ${scrolled ? 'affix' : ''}`}
            >
                <div className='container'>
                    <NavLink className='navbar-brand d-lg-none' to='/'>
                        <img
                            src={logo}
                            width={200}
                            className='img-fluid'
                            alt='logo'
                        />
                    </NavLink>
                    <button
                        className='navbar-toggler'
                        type='button'
                        data-toggle='collapse'
                        data-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='ti-menu' />
                    </button>
                    <div
                        className='collapse navbar-collapse main-menu'
                        id='navbarSupportedContent'
                    >
                        <ul className='navbar-nav mx-auto'>
                            <li className='nav-item'>
                                <NavLink exact className='nav-link' to='/'>
                                    Home
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink
                                    exact
                                    className='nav-link'
                                    to='/berita'
                                >
                                    Berita
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/peraturan'>
                                    Peraturan
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/nidn'>
                                    NIDN
                                </NavLink>
                            </li>
                            <li className='nav-item dropdown'>
                                <a
                                    className='nav-link dropdown-toggle'
                                    href='./'
                                    id='layanan-nav'
                                    role='button'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Layanan
                                    {/* <span className='custom-nav-badge badge badge-danger badge-pill'>
                                        Baru
                                    </span> */}
                                </a>
                                <div
                                    className='dropdown-menu submenu'
                                    aria-labelledby='layanan-nav'
                                >
                                    <NavLink
                                        to='/layanan'
                                        activeClassName=''
                                        className='dropdown-item'
                                    >
                                        Daftar Layanan
                                    </NavLink>
                                    {/* <NavLink
                                        to='/'
                                        activeClassName=''
                                        className='dropdown-item'>
                                        Cek Layanan
                                        <span className='badge badge-danger badge-pill ml-2'>
                                            Baru
                                        </span>
                                    </NavLink> */}
                                </div>
                            </li>
                            <li className='nav-item dropdown'>
                                <NavLink
                                    className='nav-link dropdown-toggle'
                                    to='/statistik'
                                    id='statistik-nav'
                                    role='button'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Statistik
                                </NavLink>
                                <div
                                    className='dropdown-menu submenu'
                                    aria-labelledby='statistik-nav'
                                >
                                    <NavLink
                                        to='/statistik/dosen'
                                        activeClassName=''
                                        className='dropdown-item'
                                    >
                                        Dosen
                                    </NavLink>
                                    <NavLink
                                        to='/statistik/tendik'
                                        activeClassName=''
                                        className='dropdown-item'
                                    >
                                        Tenaga Kependidikan
                                    </NavLink>
                                </div>
                            </li>
                            {/* <li className='nav-item'>
                                <a
                                    className='nav-link'
                                    href='/proses-bisnis'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Proses Bisnis
                                </a>
                            </li> */}
                            {/* <li className='nav-item'>
                                <a
                                    className='nav-link'
                                    href='https://krisan.untad.ac.id'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Kritik & Saran
                                </a>
                            </li> */}
                            {/* <li className='nav-item'>
                                <Link
                                    smooth
                                    to='kontak'
                                    className='nav-link'
                                    style={{ cursor: 'pointer' }}>
                                    Hubungi Kami
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
