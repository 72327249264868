import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
// import 'datatables.net-dt/js/dataTables.bootstrap4.min'
// import 'datatables.net-dt/css/dataTables.bootstrap4.min.css'
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import { LinearProgress } from '@material-ui/core';

import $ from 'jquery';

function DosenFakultas(props) {
    let id = useParams();
    const [label, setLabel] = useState([]);
    const [fakultas, setFakultas] = useState([]);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);

    const getLabel = async () => {
        try {
            let { data } = await axios.get(
                'statistik/dosen/label?id=' + id.fakultas
            );
            setLabel(data.label);
        } catch (e) {
            console.log(e.message);
        }
    };

    const getFakultas = async () => {
        setLoading(true);
        try {
            let { data } = await axios.get(
                'statistik/dosen/fakultas?id=' + id.fakultas
            );
            setLoading(false);
            setFakultas(data.data);
            $('.datatable').DataTable();
        } catch (e) {
            console.log(e.message);
        }
    };

    const getUnits = async () => {
        try {
            let { data } = await axios.get('statistik/dosen/label');
            setUnits(data.label);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getFakultas();
        getLabel();
        if (label.nama) {
            document.getElementById('header-subtitle').innerHTML = label.nama;
        }
        getUnits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [label.nama, id]);

    return (
        <section className='main pt-5 pb-100 gray-light-bg'>
            <div className='container'>
                <div className='row justify-content-center mt-1 mb-5 tag-cloud'>
                    {units.map((unit, index) => {
                        return (
                            <NavLink
                                key={index}
                                title={`Daftar Dosen ${unit}`}
                                to={`/statistik/dosen/${unit.toLowerCase()}`}
                            >
                                {unit}
                            </NavLink>
                        );
                    })}
                </div>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <div className='row justify-content-center'>
                            <div className='table-responsive'>
                                <div
                                    className='alert alert-info alert-dismissible fade show small'
                                    role='alert'
                                >
                                    <strong>
                                        <span className='ti-info-alt mr-2' />
                                    </strong>
                                    Data ini terus diperbarui. Jika terdapat
                                    perbedaan data, mohon menghubungi
                                    Kepegawaian Universitas.
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='alert'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <table
                                    className='table table-striped table-bordered datatable'
                                    style={{ width: '100%' }}
                                >
                                    <thead
                                        className='font-weight-bold text-white'
                                        style={{ backgroundColor: '#7952b3' }}
                                    >
                                        <tr className='text-center'>
                                            <th scope='col'>No</th>
                                            <th scope='col'>NIP</th>
                                            <th
                                                scope='col'
                                                className='text-left'
                                            >
                                                Nama Dosen
                                            </th>
                                            <th scope='col'>Fungsional</th>
                                            <th scope='col'>Gol.</th>
                                            <th scope='col'>Pendidikan</th>
                                            <th scope='col'>NIDN</th>
                                            <th scope='col'>Serdos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fakultas.map((dosen, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className='text-center'
                                                >
                                                    <th scope='row'>
                                                        {index + 1}
                                                    </th>
                                                    <td>{dosen.nip}</td>
                                                    <td className='text-left'>
                                                        {dosen.nama}
                                                    </td>
                                                    <td>
                                                        {
                                                            dosen.fungsional
                                                                ?.nama_fungsional
                                                                .jabatan
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            dosen.pangkat
                                                                ?.nama_pangkat
                                                                .golongan
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            dosen.pendidikan
                                                                ?.nama_pendidikan
                                                                .jenjang
                                                        }
                                                    </td>
                                                    <td>{dosen.nidn}</td>
                                                    <td>
                                                        {dosen.serdos ===
                                                        'Y' ? (
                                                            <span className='ti-check text-success font-weight-bold' />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='text-center'>
                            <NavLink
                                to='/statistik/dosen'
                                className='btn solid-btn mt-1'
                            >
                                Kembali
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
            <style></style>
        </section>
    );
}

export default DosenFakultas;
