import Routes from './routes/index'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function App() {
    const { pathname } = useLocation()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [pathname])
    return (
        <div className='App'>
            <Routes />
        </div>
    )
}

export default App
