import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'

function Sidebar(props) {
    const [berita, setBerita] = useState([])

    const getBerita = async () => {
        try {
            let { data } = await axios.get(`berita/4`)
            setBerita(data.data)
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        getBerita()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='col-lg-4 col-md-4'>
            <div className='sidebar-right pl-4'>
                {/*
                <aside className='widget widget-search'>
                    <form>
                        <input
                            className='form-control'
                            type='search'
                            placeholder='Type Search Words'
                        />
                        <button className='search-button' type='submit'>
                            <span className='ti-search' />
                        </button>
                    </form>
                </aside>
                {/* Categories widget*
                <aside className='widget widget-categories'>
                    <div className='widget-title'>
                        <h6>Kategori</h6>
                    </div>
                    <ul>
                        <li>
                            <a href='./'>
                                Journey
                                <span className='float-right'>112</span>
                            </a>
                        </li>
                        <li>
                            <a href='./'>
                                Development
                                <span className='float-right'>86</span>
                            </a>
                        </li>
                        <li>
                            <a href='./'>
                                Sport
                                <span className='float-right'>10</span>
                            </a>
                        </li>
                        <li>
                            <a href='./'>
                                Photography
                                <span className='float-right'>144</span>
                            </a>
                        </li>
                        <li>
                            <a href='./'>
                                Symphony
                                <span className='float-right'>18</span>
                            </a>
                        </li>
                    </ul>
                </aside>
                */}
                {/* Recent entries widget*/}
                <aside className='widget widget-recent-entries-custom'>
                    <div className='widget-title'>
                        <h6>Berita Terbaru</h6>
                    </div>
                    <ul>
                        {berita.map((item, index) => {
                            return (
                                <li key={index} className='clearfix'>
                                    <div className='wi'>
                                        <a href={`/berita/${item.slug}`}>
                                            <img
                                                src={item.gambar}
                                                alt='berita terbaru'
                                                className='img-fluid rounded'
                                            />
                                        </a>
                                    </div>
                                    <div className='wb'>
                                        <a href={`/berita/${item.slug}`}>
                                            {item.judul}
                                        </a>
                                        <span className='post-date'>
                                            {item.published_at}
                                        </span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </aside>
                {/* Tags widget*/}
                <aside className='widget widget-tag-cloud'>
                    <div className='widget-title'>
                        <h6>Tags</h6>
                    </div>
                    <div className='tag-cloud'>
                        <NavLink to='/berita'>Berita</NavLink>
                        <NavLink to='/berita'>Pengumuman</NavLink>
                        <NavLink to='/berita'>Kegiatan</NavLink>
                    </div>
                </aside>
            </div>
        </div>
    )
}

export default Sidebar
