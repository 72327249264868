import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CountUp from 'react-countup';

function Counter() {
    const [pnsDosen, setPnsDosen] = useState([]);
    const [pppkDosen, setPppkDosen] = useState([]);
    const [nonPnsDosen, setNonPnsDosen] = useState([]);
    const [pnsTendik, setPnsTendik] = useState([]);
    const [pppkTendik, setPppkTendik] = useState([]);
    const [nonPnsTendik, setNonPnsTendik] = useState([]);

    const getCount = async () => {
        try {
            let { data } = await axios.get('counter');
            setPnsDosen(data.pnsDosen);
            setPnsTendik(data.pnsTendik);
            setNonPnsDosen(data.nonPnsDosen);
            setNonPnsTendik(data.nonPnsTendik);
            setPppkDosen(data.pppkDosen);
            setPppkTendik(data.pppkTendik);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getCount();
    }, []);
    return (
        <div className='row'>
            <ul className='list-inline counter-wrap'>
                <li className='list-inline-item'>
                    <div className='single-counter text-center'>
                        <span>
                            <CountUp end={Number(pnsDosen)} duration={4} />
                        </span>
                        <h6>PNS Dosen</h6>
                    </div>
                </li>
                <li className='list-inline-item'>
                    <div className='single-counter text-center'>
                        <span>
                            <CountUp end={Number(pnsTendik)} duration={4} />
                        </span>
                        <h6>PNS Tendik</h6>
                    </div>
                </li>
                <li className='list-inline-item'>
                    <div className='single-counter text-center'>
                        <span>
                            <CountUp end={Number(pppkDosen)} duration={4} />
                        </span>
                        <h6>PPPK Dosen</h6>
                    </div>
                </li>
                <li className='list-inline-item'>
                    <div className='single-counter text-center'>
                        <span>
                            <CountUp end={Number(pppkTendik)} duration={4} />
                        </span>
                        <h6>PPPK Tendik</h6>
                    </div>
                </li>
                <li className='list-inline-item'>
                    <div className='single-counter text-center'>
                        <span>
                            <CountUp end={Number(nonPnsDosen)} duration={4} />
                        </span>
                        <h6>Non-PNS Dosen</h6>
                    </div>
                </li>
                <li className='list-inline-item'>
                    <div className='single-counter text-center'>
                        <span>
                            <CountUp end={Number(nonPnsTendik)} duration={4} />
                        </span>
                        <h6>Non-PNS Tendik</h6>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Counter;
