import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Sidebar from './../../components/Sidebar';
// import { NavLink } from 'react-router-dom';

function BeritaShow() {
    let { slug } = useParams();
    const [berita, setBerita] = useState([]);

    const getBerita = async () => {
        try {
            let { data } = await axios.get('berita/' + slug);
            setBerita(data.data);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getBerita();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='main pt-100'>
            <div className='module'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8'>
                            <article className='post'>
                                <div className='post-preview mt-4'>
                                    <img
                                        src={berita.gambar}
                                        alt=''
                                        className='img-fluid'
                                    />
                                </div>
                                <div className='post-wrapper'>
                                    <div className='post-header'>
                                        <h1 className='post-title'>
                                            {berita.judul}
                                        </h1>
                                        <ul className='post-meta'>
                                            <li>{berita.published_at}</li>
                                            <li>Dilihat {berita.views} kali</li>
                                        </ul>
                                    </div>
                                    <div className='post-content text-justify'>
                                        {parse(berita.konten || '')}
                                    </div>
                                    {/* <div className='post-footer'>
                                        <div className='post-tags'>
                                            <NavLink to='/berita'>
                                                Berita
                                            </NavLink>
                                            <NavLink to='/berita'>
                                                Pengumuman
                                            </NavLink>
                                            <NavLink to='/berita'>
                                                Kegiatan
                                            </NavLink>
                                        </div>
                                    </div> */}
                                </div>
                            </article>
                        </div>
                        {/* sidebar */}
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BeritaShow;
