import React from 'react'
import cover from '../../assets/img/cover/2.jpg'
import { NavLink } from 'react-router-dom'

function NotFound() {
    return (
        <section
            className='hero-section ptb-100 background-img full-screen'
            style={{
                background: `url(${cover})no-repeat center center / cover`,
            }}>
            <div className='container'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-md-9 col-lg-7'>
                        <div className='error-content text-center text-white'>
                            <div className='notfound-404'>
                                <h1 className='text-white'>404</h1>
                            </div>
                            <h3 className='text-white'>
                                Halaman tidak ditemukan
                            </h3>
                            <p>
                                Halaman yang anda cari saat ini sedang tidak
                                tersedia.
                            </p>
                            <NavLink className='btn app-store-btn' to='/'>
                                Kembali
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotFound
