import React, { useState, useEffect } from 'react';
import axios from 'axios';
import News from '../../components/News';

function BeritaIndex(props) {
    const [berita, setBerita] = useState([]);

    const getBerita = async () => {
        try {
            let { data } = await axios.get('berita/9');
            setBerita(data.data);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getBerita();
    }, []);

    return (
        <section className='our-blog-section ptb-100 gray-light-bg'>
            <div className='container'>
                <div className='row'>
                    {berita.map((item, index) => {
                        return (
                            <News
                                key={index}
                                title={item.judul}
                                img={item.gambar}
                                link={item.slug}
                                date={item.published_at}
                                views={item.views}
                                category='Berita'
                            />
                        );
                    })}
                </div>
                {/*pagination start*/}
                {/* <div className='row'>
                    <div className='col-md-12'>
                        <nav className='custom-pagination-nav mt-4'>
                            <ul className='pagination justify-content-center'>
                                <li className='page-item'>
                                    <a className='page-link' href='#'>
                                        <span className='ti-angle-left' />
                                    </a>
                                </li>
                                <li className='page-item active'>
                                    <a className='page-link' href='#'>
                                        1
                                    </a>
                                </li>
                                <li className='page-item'>
                                    <a className='page-link' href='#'>
                                        2
                                    </a>
                                </li>
                                <li className='page-item'>
                                    <a className='page-link' href='#'>
                                        3
                                    </a>
                                </li>
                                <li className='page-item'>
                                    <a className='page-link' href='#'>
                                        4
                                    </a>
                                </li>
                                <li className='page-item'>
                                    <a className='page-link' href='#'>
                                        <span className='ti-angle-right' />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                 */}
                {/*pagination end*/}
            </div>
        </section>
    );
}

export default BeritaIndex;
