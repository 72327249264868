import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../views/Home';
import NotFound from './../views/errors/NotFound';
import Footer from './../components/Footer';
import Navbar from './../components/Navbar';
import Hero from '../components/Hero';
import Header from './../components/Header';
import BeritaShow from '../views/berita/Show';
import BeritaIndex from './../views/berita/Index';
import PeraturanIndex from './../views/peraturan/Index';
import Carousel from './../components/Carousel';
import DosenIndex from './../views/statistik/dosen/Index';
import DosenRekap from './../views/statistik/dosen/Rekap';
import DosenPendidikan from './../views/statistik/dosen/Pendidikan';
import DosenFungsional from './../views/statistik/dosen/Fungsional';
import NidnIndex from './../views/nidn/Index';
import DosenFakultas from './../views/statistik/dosen/Fakultas';
import LayananIndex from '../views/layanan/Index';
import LayananShow from '../views/layanan/Show';
import TendikIndex from '../views/statistik/tendik/Index';
import TendikRekap from '../views/statistik/tendik/Rekap';
import TendikPendidikan from '../views/statistik/tendik/Pendidikan';
import TendikFakultas from '../views/statistik/tendik/Fakultas';

function Routes() {
    return (
        <div>
            <Switch>
                <Route exact path='/'>
                    <Navbar />
                    <Hero />
                    <Home />
                    <Carousel />
                    <Footer />
                </Route>
                <Route exact path='/berita'>
                    <Navbar />
                    <Header title='Berita Terbaru' subtitle='' />
                    <BeritaIndex />
                    <Footer />
                </Route>
                <Route path='/berita/:slug'>
                    <Navbar isAffix />
                    <BeritaShow />
                    <Footer />
                </Route>
                <Route exact path='/peraturan'>
                    <Navbar />
                    <Header title='Daftar Peraturan' subtitle='' />
                    <PeraturanIndex />
                    <Footer />
                </Route>
                <Route exact path='/nidn'>
                    <Navbar />
                    <Header
                        title='NIDN Terbit'
                        subtitle='Daftar 100 registrasi pendidik terakhir yang baru terbit'
                    />
                    <NidnIndex />
                    <Footer />
                </Route>
                <Route exact path='/layanan'>
                    <Navbar />
                    <Header title='Daftar Layanan' subtitle='' />
                    <LayananIndex />
                    <Footer />
                </Route>
                <Route path='/layanan/:slug'>
                    <Navbar isAffix />
                    <LayananShow />
                    <Footer />
                </Route>
                <Route exact path='/statistik/dosen'>
                    <Navbar />
                    <Header title='Statistik Dosen' subtitle='' />
                    <DosenIndex />
                    <Footer />
                </Route>
                <Route exact path='/statistik/dosen/rekap'>
                    <Navbar />
                    <Header title='Rekap Dosen' subtitle='' />
                    <DosenRekap />
                    <Footer />
                </Route>
                <Route exact path='/statistik/dosen/pendidikan'>
                    <Navbar />
                    <Header title='Pendidikan Dosen' subtitle='' />
                    <DosenPendidikan />
                    <Footer />
                </Route>
                <Route exact path='/statistik/dosen/fungsional'>
                    <Navbar />
                    <Header title='Fungsional Dosen' subtitle='' />
                    <DosenFungsional />
                    <Footer />
                </Route>
                <Route exact path='/statistik/dosen/:fakultas'>
                    <Navbar />
                    <Header title='Daftar Dosen' />
                    <DosenFakultas />
                    <Footer />
                </Route>
                <Route exact path='/statistik/tendik'>
                    <Navbar />
                    <Header title='Statistik Tendik' subtitle='' />
                    <TendikIndex />
                    <Footer />
                </Route>
                <Route exact path='/statistik/tendik/rekap'>
                    <Navbar />
                    <Header title='Rekap Tendik' subtitle='' />
                    <TendikRekap />
                    <Footer />
                </Route>
                <Route exact path='/statistik/tendik/pendidikan'>
                    <Navbar />
                    <Header title='Pendidikan Tendik' subtitle='' />
                    <TendikPendidikan />
                    <Footer />
                </Route>
                <Route exact path='/statistik/tendik/:fakultas'>
                    <Navbar />
                    <Header title='Daftar Tendik' />
                    <TendikFakultas />
                    <Footer />
                </Route>
                <Route path='*' component={NotFound} />
            </Switch>
        </div>
    );
}

export default Routes;
