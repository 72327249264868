import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
// import 'datatables.net-dt/js/dataTables.bootstrap4.min'
// import 'datatables.net-dt/css/dataTables.bootstrap4.min.css'
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { LinearProgress } from "@material-ui/core";

import $ from "jquery";

function NidnIndex(props) {
  const [nidn, setNidn] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNidn = async () => {
    setLoading(true);
    try {
      let { data } = await axios.get("nidn/100");
      setLoading(false);
      setNidn(data.data);
      $(".datatable").DataTable();
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getNidn();
  }, []);

  return (
    <section className="main pt-5 pb-100">
      <div className="container">
        {loading ? (
          <LinearProgress />
        ) : (
          <div className="row justify-content-center">
            <div className="table-responsive">
              <table
                className="table table-striped table-bordered datatable"
                style={{ width: "100%" }}
              >
                <thead
                  className="font-weight-bold text-white"
                  style={{ backgroundColor: "#7952b3" }}
                >
                  <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">Jenis</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-left">
                      Prosedur
                    </th>
                    <th scope="col" className="text-left">
                      Nama Dosen
                    </th>
                    <th scope="col">No. Registrasi</th>
                  </tr>
                </thead>
                <tbody>
                  {nidn.map((item, index) => {
                    return (
                      <tr key={index} className="text-center">
                        <th scope="row">{index + 1}</th>
                        <td>{item.jenis}</td>
                        <td>{item.status}</td>
                        <td className="text-left">{item.prosedur}</td>
                        <td className="text-left">{item.nama}</td>
                        <td>{item.registrasi}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default NidnIndex;
